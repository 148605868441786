import { commonService } from '.';

const getDcDetails = (body) => {
    return commonService.getWithParam('Dc/details', body);
};

const getDcWithLang = (body) => {
    return commonService.getWithParam('Dc/getDc', body);
};

const downLoadDC = (body) => {
    return commonService.getWithParam('SpireDoc/downLoadDC', body);
};

const getExperiences = (body) => {
    return commonService.getWithParam('Dc/experiences', body);
};

const insertDc = (body, param) => {
    return commonService.postBodyWithParam('Dc/save', body, param);
};

const updateDc = (body, param) => {
    console.log("DATA : " + JSON.stringify(body.skills))
    return commonService.putBodyWithParam('Dc/update', body, param);
};

const updateDcStatus = (body) => {
    return commonService.putWithParam('Dc/updateStatus', body);
};

const createNewVersion = (body) => {
    return commonService.postWithParam('Dc/createNewVersionDc', body);
};

const sendMailDC = (body, idLang) => {
    return commonService.post('Dc/sendEmailDC?idLang=' + idLang, body);
}

const infoUserDc = (idUser) => {
    return commonService.getWithParam('Dc/infoUser?idUser=' + idUser);
}

const getDcSkills = (body) => {
    return commonService.getWithParam('Dc/dcSkills', body);
};

export const dcService = {
    getDcDetails,
    getDcWithLang,
    downLoadDC,
    getExperiences,
    insertDc,
    updateDc,
    updateDcStatus,
    createNewVersion,
    sendMailDC,
    infoUserDc,
    getDcSkills
};
