import { commonService } from '.';

const getProfile = (body) => {
    return commonService.getWithParam('User/profile', body);
};

const getUserDc = (body) => {
    return commonService.getWithParam('User/userDc', body);
};

const updateLang = (body) => {
    return commonService.putWithParam('User/updateLang', body);
};

const getUserAccount = (email, idRole) => {
    return commonService.getWithParam('User/userAccount?email=' + email + '&idRole=' + idRole, null)
}

const getUserInfos = (idUser) => {
    return commonService.getWithParam('User/infos?idUser=' + idUser, null)
}
// const hasManagerPermission = (idUserData, idUserConnected, page, action) => {
//     return commonService.getWithParam('User/hasManagerPermission?idUserData=' + idUserData + '&idUserConnected=' + idUserConnected + '&page=' + page + '&action=' + action, null);
// }
const hasManagerPermission = (idUserData, idUserConnected, page) => {
    return commonService.getWithParam('User/hasManagerPermission?idUserData=' + idUserData + '&idUserConnected=' + idUserConnected + '&page=' + page, null);
}

export const userService = {
    getProfile,
    getUserDc,
    updateLang,
    getUserAccount,
    getUserInfos,
    hasManagerPermission
};